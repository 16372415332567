import { createWebHistory, createRouter } from "vue-router"

import Home from './pages/Home.vue'
import Game from './pages/Game.vue'
import Roadmap from './pages/Roadmap.vue'
import Distribute from './pages/Distribute.vue'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'Home'
  },
  {
    path: '/game',
    component: Game,
    name: 'Game'
  },
  {
    path: '/roadmap',
    component: Roadmap,
    name: 'Roadmap'
  },
  {
    path: '/distribute',
    component: Distribute,
    name: 'Distribute'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  mode: 'history',
})

export default router