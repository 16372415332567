<template>
  <div :id="this.path" class="artHolder">
    <img :src='getPath' alt="">
  </div>
</template>

<script>
export default {
  name: "Art",
  props: {
    path: String
  },
  computed: {
    getPath(){
      return `./arts/${this.path}`
    }
  },
  mounted(){
    const artHolder = document.getElementById(this.path)
    console.log(artHolder);

    // Box    
    let box = null
    function resize(){
      box = artHolder.getBoundingClientRect()
    }
    document.addEventListener('resize', () => {
      resize()
    })
    resize()

    artHolder.addEventListener('mousemove', event => {
      console.log((((event.clientY - box.y) - (box.height/2)) / box.height));
      artHolder.style.transform = `rotateY(${(((event.clientX - box.x) - (box.width/2)) / box.width) * 15}deg) rotateX(${(((event.clientY - box.y) - (box.height/2)) / box.height) * 2}deg)`
    })

    artHolder.addEventListener('mouseleave', () => {
      artHolder.style.transform = 'rotateX(0deg) rotateY(0deg)'
    })
  }
}
</script>

<style scoped>
@media (orientation: landscape) {  
  .artHolder
  {
    width: 50vw;
  }
}
@media (orientation: portrait) {  
  .artHolder
  {
    width: 80vw;
  }
}
.artHolder
{
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.artHolder img
{
  object-fit: contain;
  height: 100%;
  width: 100%;
}
</style>