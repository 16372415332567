<template>
  <div id="textHodler"> 
  </div>
</template>

<script>
export default {
  name: "TypeText",
  props: {
    fullText: String
  },
  mounted(){
    let id = 0
    const local = this

    let textElement
    let text = ''

    const textHodler = document.getElementById('textHodler')

    function newText(){
      textElement = document.createElement('p')
      textElement.style.margin = "0"
      console.log(textElement);
      textHodler.appendChild(textElement)
      text = ''
    }
    newText()

    function tick(){
      if(local.fullText[id] === '&'){
        newText()
      }
      else{
        text += local.fullText[id]
        textElement.innerText = text
      }


      id += 1
      if(id !== local.fullText.length){
        setTimeout(() => {
          tick()
        }, Math.random() * 30)
      }
    }
    tick()    
  }
}
</script>

<style scoped>
#textHodler
{
  font-family: arial, sans-serif;
}
</style>