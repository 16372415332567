<template>
  <router-link to="/" id="home">Home</router-link>
  <div class="popupPage">
    <iframe
      src="https://i.simmer.io/@Mylittledino/mylittledinodemo"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Game',
}
</script>

<style scoped>
#home
{
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;

  margin: 1vh;
  z-index: 2;
}
.popupPage {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
}
iframe
{
  height: 100vh;
  width: 100vw;
}
</style>