<template>
  <LoadingScreen ref="loadingScreen"/>
  <Scene3d ref="scene3D" @load="load"/>
</template>

<script>
import Scene3d from '../components/Scene3d.vue'
import LoadingScreen from '../components/LoadingScreen.vue'

export default {
  name: 'Home',
  components: { Scene3d, LoadingScreen },
  data(){
    return {
      laodCount: 0,
      maxElementCount: 48
    }
  },
  methods: {
    load(){
      this.laodCount += 1

      if (this.laodCount == this.maxElementCount){
        this.$refs.loadingScreen.closeLoader()
        this.$refs.scene3D.startAnimation()
      }
    }
  }
}
</script>