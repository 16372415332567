<template>
  <router-view />
</template>

<script>
import soundfire from './matches-1.mp3'
import sound from './mylittledinostartsound.mp3'

export default {
  name: 'App',
  mounted(){
    const fire = new Audio(soundfire)
    const music = new Audio(sound)
    console.log(music);

    let canLoop = true
    document.addEventListener('wheel', () => {
      if(canLoop){
        canLoop = false
        wait()

        fire.currentTime = 0
        fire.play()
      }
    })

    function wait(){
      setTimeout(() => {
        canLoop = true
      }, 1000)
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik+Puddles&display=swap');
.gpuAcceleration
{
  will-change: transform;
}
</style>
