<template>
  <div id="popup" class="gpuAcceleration">
    <div id="close" @click="close">
      <img :src="require('../icons/close.png')" />
    </div>
    <div id="popUpBody">
      <div v-if="componentName == 1" class="popupPage">
      </div>
      <div v-else-if="componentName == 2" class="popupPage">
        <div v-if="playVideo" class="inline">
          <video class="videoPlayer" autoplay @ended="playVideo = false">
            <source src="../video/What_is_my_little_dino.mp4" type=video/mp4 >
          </video>
          <button class="skipButton" @click="playVideo = false">Skip</button>
        </div>
        <template v-else>
          <h1>What's My Little Dino?</h1>
          <TypeText fullText=
            "My Little Dino is the ultimate collection for all passionate NFT enthusiasts worldwide. Available on the Solana Blockchain, this collection has 4000 unique and rare Little Dino’s NFTs that have been creatively designed by the best NFT experts ever.
            &
            Fusing innovation and gaming in the most creative way, My Little Dino will allow you to compete with other community members in the most enjoyable and exciting game available on blockchain. To make things even more interesting, you will be able to earn a potion of the total reward after each competition.
            &
            This is because My Little Dino’s game has been designed to be an inclusive source of fun that immensely benefits everyone. You will enjoy the nice, innovative, and aesthetic 3D design of your little Dino that you will be able to use on the metaverse and inside the game itself.
            &
            The funds collected from the sales will then be used to facilitate the development of my little dino.   As you may know, staking a big amount of money gives you a higher reward. That is where My Little Dino funds will be used.Rewards will be used to buyback each week nft/Lava tokens.
            &
            For the most exciting and rewarding experience, we will make My Little Dino a democratized NFT project. This means that holders will have the power to decide and upvote about futur opportunities of the project via a private discord. Additionally, each week a new game level is unlocked, holders will be able to compete against others and the top 500 players will receive a share of the weekly reward (check Lava token section for more information).To participate in the weekly game levels ,holders would need to stake their nft for a minimum period of 1 month for 4  weeks participation.
            &
            Why should you be part of My Little Dino’s Community?
            &
            My little Dino NFT will give you the following benefits:
            - Access to the 3D metaverse and our online game My Little Dino.
            - Access to a highly addictive game with appealing weekly rewards.
            - Access to future projects/whitelist that you would not be able to join otherwise.
            - Access to future NFT  giveaways.
            - Access to staking.
            - And multiples others opportunities.
            &
            The primary objective of My Little Dino is to be more than just an NFT project. Rather, we aim to make it a cohesive community of the most passionate NFT enthusiasts. We will be constantly innovating to improve and find new ways to make the holders happy and satisfied.
            You are not just an ordinary holder; no. You are part of a larger family, a member of a tight-knit community brought together by their love of NFTs. Wouldn’t you love to be part of that? Then join us today!
            "
          />
        </template>       
      </div>
      <div v-else-if="componentName == 3" class="popupPage">
        <h1>What is Lava Token?</h1>
        <TypeText fullText="
          Lava Tokens or in other words the Dino Currency of Choices.
          We aim to make My Little Dino the most unique and exciting NFT project in the metaverse. Therefore, we have ensured that our NFT project has its own special, in-game currency called Lava Tokens. As such, our reward system will exclusively be facilitated by Lava Tokens to preserve the integrity and ultra-premium status of the project.
          &
          This may make you wonder: 
          How do I stand to benefit from this exclusive in-game currency? Well, each week, every address staking their NFT will receive 2 free Lava Tokens regardless of their performance in the gaming competition! As such, staking multiples NFTs will grant you access to multiples lava tokens every week and you will be able to build yourself a nice little stash that will boost your fortunes in the game. Wouldn’t you love that?
          &
          Once you have received your lava tokens, you can then use them to play and compete with other players. You will have to spend one lava token—which is equivalent to one life cycle—to buy into the game and begin competing. That is why having multiples NFTs will be immensely beneficial for you. Multiple NFTs will give you more lava tokens which you’ll then use to search for faster routes and faster ways to complete the different levels of the game.
          This leads us to the next question: Why do you have to complete the different game levels within a specific time frame?
          &
          Well, there will be a weekly leaderboard where players who have completed the week’s game levels will be ranked in order from the first to the last. Only the top 500 players will be ranked and so players who don’t complete the required game levels will not feature on the leaderboard. This is why holding multiple NFTs and using them to stack many lava tokens will be extremely advantageous.
          This is because you can use your tokens to replay tough levels repeatedly so you can find the fastest route to outperfom other players. If you do complete the required levels, you will be ranked in the top 500 and receive free lava tokens.
          &
          You can then use those tokens to replay the next week’s game levels a few more time than the 2 weekly life cycles you are granted. If not, you can out your tokens in exchange of others rewards such as whitelist for future nft ,free nft giveaways ,free physical gifts ,free solana and many more opportunities such as using the token for cosmetics purpose or to increase the rarities of your dino.
          &
          The weekly Rewards will be distributed as follows:
          &
          Top1:150 Lava Tokens
          Top2:75 Lava Tokens
          Top3:50 Lava Tokens
          Top4-10:30 Lava Tokens
          Top11-20:20 Lava Tokens
          Top21-100:10 Lava Tokens
          Top101-500:5 Lava Tokens
          &
          How will these Lava Tokens be Distributed every Week?
          Note that rewards/giveaways will be paid from the money received by the staking of the funds collected from the royalties/NFT .The remaining  will be reinvested in future opportunities such as laucnhpad,partnerships or alternativers ideas to generate more revenue according to the discord'votes 
          The game has also been designed to be actively challenging and addictively engaging. That way, players are persuaded to reuse their lava tokens multiples times to complete the game. This makes the game more exciting and rewarding without letting the desire to stack lava tokens get in the way of offering all players maximum fun.
          &
          Note that in other to compete,players will need to stake their nft for 1 month atleast to receive their free 2 lava tokens for the next 4 weeks.
          1 Month staking needed for 2 lava tokens per week for the next 4 weeks.
          3 Month staking needed for 3 lava tokens per week for the next 12 weeks.
          6 Month staking needed for 4 lava tokens per week for the next 24 weeks.
          "
        />
      </div>
      <div v-else-if="componentName == 4" class="popupPage">
        <!-- <TypeText fullText="Our prime concern is innovation, we are ready to adapt to every situation .As of now 9 levels are already made for the next 9 weeks after the launch of the game.We are also working on releasing a multiplayer version of the game where player could wager their lava tokens amongst others  but it won't be limited to that.Many more key features will be released gradually.Stay in contact for an updated roadmap!"/> -->
      </div>
      <div v-else-if="componentName == 5" class="popupPage">
        <a href="https://twitter.com/MyLittleDinoSOL" target="_blank" class="linkSocials">Twitter</a>
        <a href="mailto:Support@mylittledinonft.com" target="_blank" class="linkSocials">Support or technical assistances</a>
        <a href="mailto:Husnain@mylittledinonft.com" target="_blank" class="linkSocials">Partnerships</a>
        <a href="https://discord.gg/6m7t6uMuuQ" target="_blank" class="linkSocials">Discord</a>
        <!-- <h1>Exchange Your Lava token for Solana</h1>
        <p>Convert your desire reward</p>
        <div class="inline">
          <p>Exchange</p>
          <input id="input" type="number" v-model="price"/>
          <p>Lava Tokens for {{ solanaValue }} Dollars solana worth</p>
        </div>
        <button id="buttonStore" v-if="!clicked" @click="clicked = true">click to convert your reward</button>
        <p v-else>Coming soon</p>
        <div>
          <img src="../icons/Lavatoken.png" />
        </div> -->
      </div>
      <div v-else-if="componentName == 6" class="popupPage">
        <Art v-for="artUrl of artlist" :key="artUrl" :path="artUrl"/>
      </div>
    </div>
  </div>
</template>

<script>
import TypeText from './TypeText.vue'
import Art from "./Art.vue"

export default {
  name: 'Popup',
  data(){
    return {
      componentName: "",
      popup: null,
      playVideo: true,
      clicked: false,
      price: 300,
      artlist: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png', '9.png', '10.png', '11.png']
    }
  },
  components: { TypeText, Art },
  computed:{
    solanaValue(){
      let value = 0
      if(this.price > 8000){
        value = this.price
      }
      else if(this.price < 300){
        value = this.price /3
      }
      else
      {
        const position = (this.price - 300) / 7700
        const ratio = 0.333333 + ( 0.666666 * position)
        value = this.price * ratio
      }

      return Math.round(value)
    }
  },
  methods: {
    close(){
      this.componentName = -1
      document.getElementById('popup').style.transform = "translateY(110vh)"

      this.$emit('close')
    },
    updatePage(element){
      this.playVideo = true
      this.clicked = false

      this.componentName = element
      document.getElementById('popup').style.transform = "translateY(0vh)"
    }
  }
}
</script>

<style scoped>
#popup {
  top: 0;
  position: absolute;
  z-index: 10;

  width: 100vw;
  height: 100vh;

  padding-top: 2vh;
  background-color: transparent;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  transform: translateY(100vh);
  transition-duration: 500ms;
}
#close {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;

  width: 5vh;
  height: 5vh;

  margin: 1%;
  z-index: 2;
}
#close img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#popUpBody {
  color: #ffffff;
  text-shadow: 0px 0px 30px #ffffff;
}
.popupPage {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;

  /* pointer-events: none; */
}
.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#input {
  font-size: 1.2em;
  width: 3em;
  height: 2em;

  padding: 0;
  margin: 0.5vh;

  background-color: transparent;
  color: #ffffff;
  border: 0;
}
.skipButton,
#buttonStore
{
  font-size: 1em;
  white-space: nowrap;

  padding: 1vh;
  margin: 1vh 0;

  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;

  transition-duration: 500ms;
}
#buttonStore:hover
{
  box-shadow: 0 0 10px #ffffff;
}
.inline
{
  display: flex;
  flex-direction: column;
}
.linkSocials
{
  color: white;
  border-bottom: 2px solid white;
  text-align: center;
  transition: text-decoration 300ms;
}
.linkSocials:hover
{
  border-bottom: 2px solid transparent;
}
.videoPlayer
{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}
.videoPlayer img
{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.skipButton
{
  position: absolute;
  z-index: 2;
  bottom: 5vh;
}
#dinopaper
{
  width: 100vw;
}
#dinopaper img
{
  width: 100%;
}
@media screen and (orientation: landscape) {
  .popupPage {
    padding: 5vh;
    padding-top: 8vh;

    font-size: 3vh;
  }
  #videoHolder {
    margin: 5vh;
    width: 80vw;
  }
  .linkSocials
  {
    font-size: 5vh;
    margin: 3vh 0;
  }
}
@media screen and (orientation: portrait) {
  .popupPage {
    padding: 5vw;
    padding-top: 8vh;

    font-size: 4vw;
  }
  #videoHolder {
    margin: 5vw;
    width: 100vw;
  }
  .linkSocials
  {
    font-size: 5vw;
    margin: 3vh 0;
  }
}
</style>
