<template>
  <div id="page">
    <h1>Redirecting to - distribution.mylittledinonft.com</h1>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Distribute',
  mounted() {
    window.location.href = 'https://distribution.mylittledinonft.com/';
  }
}
</script>

<style scoped>
#page
{
  z-index: 10;
  padding: 5vh;
  display: none;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  overflow-y: auto;
}
</style>