<template>
  <div id="loadingScreen" class="gpuAcceleration" v-if="loading">
    <img src="../assets/IMG_2643.gif">
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  data(){
    return{
      loading: true
    }
  },
  methods: {
    closeLoader(){
      document.getElementById('loadingScreen').style.opacity = "0"

      setTimeout(() => {
        this.loading = false
      }, 2500)
    }
  },
}
</script>

<style scopde>
#loadingScreen
{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  transition: opacity 1000ms ease-out;
}
#loadingScreen img
{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>