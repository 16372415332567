<template>
  <div id="page">
    <h1>Roadmap:</h1>
    <div id="dinopaper">
      <img src="../assets/dino.jpg">
    </div>
  </div>
</template>

<script>
export default {
 name: 'Roadmap'
}
</script>

<style scoped>
#page
{
  z-index: 10;
  padding: 5vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  overflow-y: auto;
}
</style>