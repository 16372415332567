<template>
  <div id="navBody">
    <div id="close" @click="close">
      <img :src="require('../icons/close.png')">
    </div>
    <div v-for="page of pages" :key="page">
      <router-link v-if="page.to" :to="page.to" @mouseenter="mouseEnter(page)" @mouseleave="mouseLeave()">{{ page.name }}</router-link>
      <p v-else @click="pageClick(page)" @mouseenter="mouseEnter(page)" @mouseleave="mouseLeave()">
        {{ page.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigator',
  emits: ['pageSelect', 'newHover'],
  data(){
    return {
      pages: {
        1: {
          name: "Game",
          id: 2,
          to: '/game'
        },
        2: {
          name: "What's My Little Dino?",
          id: 2
        },
        3: {
          name: "Lava Token",
          id: 3
        },
        4: {
          name: "Dinopaper/Roadmap",
          id: 4,
          to: '/roadmap'
        },
        5: {
          name: "Socials",
          id: 5
        },
        6: {
          name: "Art sneak peek",
          id: 6
        },
        7: {
          name: "Music Distribution",
          id: 7,
          to: '/distribute'
        },
      }
    }
  },
  methods: {
    open(){
      document.getElementById('navBody').style.top = "0vh"
    },
    pageClick(page){
      document.getElementById('navBody').style.top = "100vh"

      this.$emit('pageSelect', page.id)
    },
    close(){
      document.getElementById('navBody').style.top = "100vh"

      this.$emit('close')
    },
    mouseEnter(page){
      document.getElementById('canvas3D').style.filter = 'blur(5px)'
      this.$emit('newHover', page)
    },
    mouseLeave(){
      document.getElementById('canvas3D').style.filter = 'blur(0px)'
    }
  }
}
</script>

<style scoped>
#navBody
{
  height: 100vh;
  width: 100vw;
  overflow-y: auto;

  position: fixed;
  top: 100vh;
  left: 0;
  z-index: 10;

  transition-duration: 500ms;

  background-color: transparent;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
#navBody *
{
  opacity: 0.7;
  cursor: pointer;
  transition-duration: 200ms;

  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 10px #ffffff,  0px 0px 10px #ffffff, 0px 0px 10px #ffffff,  0px 0px 10px #ffffff;
  text-align: center;
  font-family: 'Rubik Puddles', cursive;
  z-index: 2;
}
#navBody *:hover
{
  transform: scale(1.05);
  opacity: 1;
}
#close
{
  position: absolute;
  cursor: pointer;

  width: 5vh;
  height: 5vh;
  right: 0;
  top: 0;

  margin: 2vh;
  z-index: 2;
}
#close img
{
  object-fit: contain;
  width: 100%;
  height: 100%;
}
/* #star
{
  position: absolute;
  top: 50%;
  left: 50%;

  height: 100px;
  width: 100px;
  border-radius: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white, 0 0 500px white;

  opacity: 0.5;
  background-color: #ffffff;
  mix-blend-mode: difference;
} */
@media (orientation: landscape) {
  #navBody *
  {
    font-size: 8vh;
    margin: 0vh 0;
  }
}
@media (orientation: portrait) {
  #navBody *
  {
    font-size: 8vw;
    margin: 0vh 0;
  }
}
</style>