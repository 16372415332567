import * as THREE from 'three'
import shader from './shader.js'

export default class Particles{
  constructor(scene){
    this.scene = scene
    this.particles

    this.createParticles()
  }  

  render(elapsed){
    // Particles
    this.particles.material.uniforms.uTime.value = elapsed
    this.particles.rotation.y = elapsed * 0.01
  }

  createParticles() {
    const count = 4000
    const expand = 4000
  
    const positions = new Float32Array(count * 3)
    for (let i = 0; i < count * 3; i++) {
      positions[i] = (Math.random() - 0.5) * expand
    }
  
    // Geometry
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3))
  
    // Material
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 200 },
        uSpeed: { value: 0.0001 },
        uStrenght: { value: 1 },
        uFrequency: { value: 2 }
      },
      transparent: true,
      depthWrite: false,
      vertexShader: shader.vertex,
      fragmentShader: shader.fragment
    })
  
    this.particles = new THREE.Points(geometry, material)
    this.scene.add(this.particles)
  }
}