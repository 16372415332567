<template>
  <Popup ref="popup" @close="popupClosed"/>
  <Navigator ref="navigator" @newHover="newHover" @pageSelect="pageSelect" @close="popupClosed"/>
  <div id="enterMessage">
    <p>
      Click on any asteroides
    </p>
  </div>
  <canvas id="canvas3D"/>
</template>

<script>
import Popup from './Popup.vue'

import * as THREE from 'three'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Particles from './particles/Particles.js'
import Navigator from './Navigator.vue'

import gsap from "gsap"

export default {
  name: 'Scene3d',
  components: { Popup, Navigator },
  emits: ['load'],
  data(){
    return {
      navPoints: require('../NavigationPoints.json'),
      currentPoint: null,
      camera: null,
      control: null,
      cameraSpeedSeconds: 2,
      cameraTarget: new THREE.Vector3(),
      animate: true,
      amplitude: { value: 1500 },
      animationEasing: { value: 1 }
    }
  },
  methods: {
    zoomOut() {
      const local = this
      this.control.enabled = false
      
      gsap.to(this.camera.position, {
        x: 0,
        y: 0,
        z: 150,
        duration: this.cameraSpeedSeconds, 
        ease: "expo",
        onComplete:function(){
          local.control.enabled = true
        }
      })

      gsap.to(this.cameraTarget, {
        x: 0,
        y: 0,
        z: 0,
        duration: this.cameraSpeedSeconds, 
        ease: "expo"
      })
    },
    animateCamera(point){
      gsap.to(this.camera.position, {
        x: point.position.x + 20,
        y: point.position.y + 20,
        z: point.position.z + 20,
        duration: this.cameraSpeedSeconds, 
        ease: "expo"
      })

      gsap.to(this.cameraTarget, {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
        duration: this.cameraSpeedSeconds, 
        ease: "expo"
      })
    },
    popupClosed(){
      this.zoomOut()
      setTimeout(() => {
        this.control.enabled = true
      }, this.cameraSpeedSeconds * 1000)
    },
    openPopup(id){
      this.control.enabled = false
      this.currentPoint = this.navPoints[id - 1]
      const element = this.navPoints[id - 1]

      this.animateCamera(element)
      setTimeout(() => {
        this.$refs.navigator.open(element)
      }, this.cameraSpeedSeconds * 500)
    },
    pageSelect(page){
      this.$refs.popup.updatePage(page)
    },
    startAnimation(){      
      // ========== //
      // Start Anim //
      // ========== //
      gsap.to(this.amplitude, {
        value: 100,
        duration: 5
      })
     
      gsap.to(this.animationEasing, {
        value: 0,
        duration: 5
      })

      setTimeout(() => {
        this.animate = false
        this.control.enabled = true

        const enterMessage = document.getElementById('enterMessage')
        enterMessage.style.opacity = 1
        setTimeout(() => {
          enterMessage.style.opacity = 0
        }, 5000)
      }, 5000)
    },
    newHover(pageHover){
      this.currentPoint = this.navPoints[pageHover.id - 1]
      const element = this.navPoints[pageHover.id - 1]

      this.animateCamera(element)
    }
  },
  mounted(){
    this.currentPoint = this.navPoints[0]

    // ======== // 
    // Renderer //
    // ======== //         
    const canvas = document.getElementById('canvas3D')
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true
    })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setClearColor( 0x000000, 1 )
    renderer.outputEncoding = THREE.BasicDepthPacking

    // ===== // 
    // Scene //
    // ===== //      
    const scene = new THREE.Scene()

    // ====== // 
    // Camera //
    // ====== //
    const camera = new THREE.PerspectiveCamera(75, 0, 0.1, 1000)
    camera.position.set(0, 0, 100)
    scene.add(camera)

    this.camera = camera
    
    // ==== // 
    // Mesh //
    // ==== //
    //Loaders
    const fbxLoader = new FBXLoader()
    const textureLoader = new THREE.TextureLoader()
    const hitboxStack = []

    // Load
    for(let point of this.navPoints){
      fbxLoader.load(`models/${point.folder}/${point.model}`, object => {
        const asteroid = object.children.find(child => child instanceof THREE.Mesh)
        asteroid.scale.set(0.1, 0.1, 0.1)
        asteroid.material = new THREE.MeshPhysicalMaterial({
          clearcoat: 0.5,
          color: 0x000000
        })
        asteroid.name = point.page

        asteroid.position.set(point.position.x, point.position.y, point.position.z)

        // Textures
        asteroid.material.map = textureLoader.load(`models/${point.folder}/${point.subFolder}/${point.textures.base}`, (texture) => {
          renderer.initTexture(texture)
          this.$emit('load')
        })
        asteroid.material.normalMap = textureLoader.load(`models/${point.folder}/${point.subFolder}/${point.textures.normal}`, (texture) => {
          renderer.initTexture(texture)
          this.$emit('load')
        })
        asteroid.material.roughnessMap = textureLoader.load(`models/${point.folder}/${point.subFolder}/${point.textures.roughness}`, (texture) => {
          renderer.initTexture(texture)
          this.$emit('load')
        })
        asteroid.material.emissiveMap = textureLoader.load(`models/${point.folder}/${point.subFolder}/${point.textures.glossiness}`, (texture) => {
          renderer.initTexture(texture)
          this.$emit('load')
        })
        asteroid.material.clearcoatMap = textureLoader.load(`models/${point.folder}/${point.subFolder}/${point.textures.clearcoat}`, (texture) => {
          renderer.initTexture(texture)
          this.$emit('load')
        })

        asteroid.speed = Math.random() * 5
        hitboxStack.push(asteroid)
        scene.add(asteroid)
        
        this.$emit('load')

      })
    }

    // ======= // 
    // Control //
    // ======= //
    const control = new OrbitControls(camera, canvas)
    this.control = control
    this.control.enabled = false

    control.target = this.cameraTarget
    control.enableDamping = true
    control.enablePan = false

    // ====== // 
    // Lights //
    // ====== //
    const ambiantLight = new THREE.AmbientLight(0xffffff, 2)
    scene.add(ambiantLight)

    const pointLight  = new THREE.PointLight(0xffffff, 5)
    pointLight.position.set(-20, 20, -20)
    pointLight.castShadow = true

    scene.add(pointLight)

    // ========= // 
    // Raycaster //
    // ========= //
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let objectDown = null

    canvas.addEventListener('mousedown', event => {
      if(this.control.enabled){
        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        
        raycaster.setFromCamera( mouse, camera )
        const intersects = raycaster.intersectObjects( hitboxStack )

        if (intersects.length > 0){
          objectDown = intersects[0].object.name
        }
        else
        {
          objectDown = null
        }
      }
    })

    canvas.addEventListener('mouseup', event => {
      if(objectDown && this.control.enabled){
        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        
        raycaster.setFromCamera( mouse, camera )
        const intersects = raycaster.intersectObjects( hitboxStack )

        if (intersects.length > 0 && objectDown === intersects[0].object.name){
          this.openPopup(intersects[0].object.name)
          objectDown = null
        }
      }
    })

    // ========= // 
    // Particles //
    // ========= //
    const particles = new Particles(scene)

    // ==== // 
    // Loop //
    // ==== //
    const clock = new THREE.Clock()
    const tick = () =>
    {
      // Update control
      control.update()

      // Rotate
      for(let asteroid of hitboxStack){
        asteroid.rotation.y += asteroid.speed * 0.001
      }

      // Animation
      if(this.animate){
        camera.position.x = Math.cos(clock.getElapsedTime() * this.animationEasing.value) * this.amplitude.value
        camera.position.z = Math.sin(clock.getElapsedTime() * this.animationEasing.value) * this.amplitude.value
        camera.position.y = Math.sin(clock.getElapsedTime() * this.animationEasing.value) * this.amplitude.value
      }

      // Render
      renderer.render(scene, camera) 

      // Recall
      window.requestAnimationFrame(tick)

      // Update Particles
      particles.render(clock.getElapsedTime())
    }
    tick()

    // ============= // 
    // Window Resize //
    // ============= //
    function updateSizes(){      
      // Store new sizes
      const sizes = {
        height: window.innerHeight,
        width: window.innerWidth
      }

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    window.addEventListener('resize', () => {
      updateSizes()
    })

    updateSizes()
  }
}
</script>

<style scoped>
#enterMessage
{
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  top: 20vh;

  opacity: 0;
  transition-duration: 500ms;
  pointer-events: none;
}
#enterMessage p
{  
  font-size: 2em;
  color: #ffffff;
  text-shadow: 0px 0px 30px #ffffff;
}
#canvas3D
{
  transition: filter 500ms;
}
</style>
